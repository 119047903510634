import { environment } from '@environments/environment';

/**
 * Handle module load error
 * Modules are hashed at build. If the module has changes, it gets a new hash
 * On deploy, server files are overwritten. Thus, some hashes change
 * Client might not be aware of these changes and request an outdated module
 * In that case, this event is emitted
 */
export const handleModuleLoadError = () => {
    if (environment.mode === 'prod') return;
    console.log('Listening to Vite preload error');
    window.addEventListener('vite:preloadError', event => {
        console.log({ error: 'vite:preloadError', event });
        // window.location.reload()
    });
};
