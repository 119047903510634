import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@features/auth/auth.service';
import { BILLING_MODAL } from '@features/settings/billing/components/billing-dashboard/billing-dashboard.config';
import { BillingService } from '@features/settings/billing/services/billing.service';
import { CancelSubSuccessModalComponent } from './features/settings/billing/components/cancel-sub-success-modal/cancel-sub-success-modal.component';
import { UpgradePricingModalComponent } from './features/settings/billing/components/upgrade-pricing-modal/upgrade-pricing-modal.component';
import { UpgradedGrowthPlanModalComponent } from './features/settings/billing/components/upgraded-growth-plan-modal/upgraded-growth-plan-modal.component';
import { handleModuleLoadError } from './vite-error-handler';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet />

        @switch (modalOpen()) {
            @case (BILLING_MODAL.cancel) {
                <set-cancel-sub-success-modal
                    class="absolute inset-0 z-20"
                    (onClose)="modalOpen.set(null)" />
            }
            @case (BILLING_MODAL.upgrade) {
                <app-upgrade-pricing-modal (onClose)="modalOpen.set(null)" />
            }
            @case (BILLING_MODAL.updateToGrowthSuccess) {
                <app-upgraded-growth-plan-modal (onClose)="modalOpen.set(null)" />
            }
        }
    `,
    imports: [
        RouterOutlet,
        CancelSubSuccessModalComponent,
        UpgradePricingModalComponent,
        UpgradedGrowthPlanModalComponent,
    ],
})
export class AppComponent {
    private readonly authService = inject(AuthService);
    private readonly billingS = inject(BillingService);

    readonly modalOpen = this.billingS.modalOpen;
    readonly BILLING_MODAL = BILLING_MODAL;

    constructor() {
        handleModuleLoadError();
        this.authService.checkIsLoggedIn();
    }
}
