<app-modal-bg (modalClick)="onClose.emit()">
    <app-modal-card
        class="w-[446px]"
        imgPreHeader="{{ imgPreHeader }}"
        [hasCloseBtn]="false"
        [header]="header">
        <div class="main text-twGrayDark text-left">
            <p class="mb-5">
                {{ TEXT.upgradedGrowthPlan.message.first }}
            </p>
            <p>
                {{ TEXT.upgradedGrowthPlan.message.second }}
            </p>
        </div>
        <footer class="flex gap-4 mt-[38px]">
            <app-button (btnClick)="onClose.emit()">
                {{ TEXT.goIt }}
            </app-button>
        </footer>
    </app-modal-card>
</app-modal-bg>
