import { Route, Routes } from '@angular/router';
import { AUDIENCE_SLUGS } from '@routes/routes.types';
export const CONTACTS_SLUGS = {
    bulkImporter: 'bulk-importer',
    table: 'table',
    profile: 'profile',
} as const;
export type ContactsSlugs = (typeof CONTACTS_SLUGS)[keyof typeof CONTACTS_SLUGS];
export const CONTACTS_ROUTES: Routes = [
    {
        path: CONTACTS_SLUGS.table,
        title: 'Overview',
        loadComponent: () =>
            import(
                '@features/audience/contacts/contacts-overview/contacts-overview.component'
            ).then(m => m.ContactsOverviewComponent),
    },
    {
        path: CONTACTS_SLUGS.profile,
        title: 'Profile',
        loadComponent: () =>
            import('@features/audience/contacts/contact-profile/contact-profile.component').then(
                m => m.ContactProfileComponent
            ),
    },
    {
        path: CONTACTS_SLUGS.bulkImporter,
        title: 'Bulk Importer',
        loadComponent: () =>
            import(
                '@features/audience/contacts/contacts-bulk-importer/contacts-bulk-importer.component'
            ).then(m => m.ContactsBulkImporterComponent),
    },
    { path: '**', redirectTo: CONTACTS_SLUGS.table },
] as const;
export const CONTACTS_ROUTE: Route = {
    path: AUDIENCE_SLUGS.contacts,
    children: CONTACTS_ROUTES,
};
