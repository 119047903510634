import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResetAppStateService } from '@core/services/reset-app-state.service';
import { AuthService } from '@features/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { catchError, throwError } from 'rxjs';

const getSlug = () => {
    const url = window.location.href;
    const urlArr = url.split('/');
    return urlArr[urlArr.length - 1];
};

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    const toastr = inject(ToastrService);
    const cookie = inject(CookieService);
    const authS = inject(AuthService);

    const token = authS.token() || cookie.get('auth.token');
    let request;

    if (token) {
        request = req.clone({
            withCredentials: true,
            setHeaders: {
                authorization: `Bearer ${token}`,
            },
        });
    } else {
        request = req.clone({ withCredentials: true });
    }

    return next(request).pipe(
        catchError((err: HttpErrorResponse) => {
            const network = 'Please check your internet connection';
            const slug = getSlug();

            switch (err.status) {
                case 0:
                    toastr.error(network);
                    break;
                case 401:
                    if (slug !== 'login') {
                        toastr.error('Please log in again', 'Session expired');
                    }
                    authS.logout();
                    break;
            }

            return throwError(() => err);
        })
    );
};
