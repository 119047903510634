import { CanActivateFn } from '@angular/router';
import { ENVIRONMENTS } from '@environments/base-env';
import { environment } from '@environments/environment';

export const devOnlyGuard: CanActivateFn = () => {
    if (environment.mode === ENVIRONMENTS.prod) {
        return false;
    }
    return true;
};
