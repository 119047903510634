import { Routes } from '@angular/router';
import { CONTACTS_ROUTE } from '@features/audience/contacts/contacts.routes';
import { PIPELINE_ROUTE } from '@features/audience/pipelines/pipelines.routes';
import { SEGMENTS_ROUTE } from '@features/audience/segments/segment.routes';
import { AUDIENCE_SLUGS } from '@routes/routes.types';

export const AUDIENCE_ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUDIENCE_SLUGS.contacts,
    },
    CONTACTS_ROUTE,
    SEGMENTS_ROUTE,
    PIPELINE_ROUTE,
    {
        path: '**',
        redirectTo: AUDIENCE_SLUGS.contacts,
    },
] as const;
