import { Route, Routes } from '@angular/router';
import { AUDIENCE_SLUGS } from '@routes/routes.types';
export const SEGMENTS_SLUGS = {
    table: 'table',
} as const;
export const SEGMENTS_ROUTES: Routes = [
    {
        path: SEGMENTS_SLUGS.table,
        title: 'Segments',
        loadComponent: () =>
            import('@features/audience/segments/segments.component').then(m => m.SegmentsComponent),
    },
    { path: '**', redirectTo: SEGMENTS_SLUGS.table },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: AUDIENCE_SLUGS.segments,
    },
] as const;
export const SEGMENTS_ROUTE: Route = {
    path: AUDIENCE_SLUGS.segments,
    children: SEGMENTS_ROUTES,
};
